<template>
  <page-header text="Item Faults" />
  <table-component
    :retrieve-table-data-function="retrieveTableData"
    :update-on-change="updateKey"
    :unique-key="'id'"
    :process-field-function="processFieldFunction"
    :hidden-fields="['id', 'updated']"
    :process-column-header-function="(h: string) => (h === 'id' ? 'ID' : h)"
    :default-active-column="'created'"
    :default-descending="true"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TableComponent from '@/components/TableComponent.vue'
import PageHeader from '@/components/PageHeader.vue'
import { createUpdatedFaultsListener } from '@/composables'
import { LocalFault, api, Filter } from '@/services/DataServices'
import type { DataSet } from '@/services/DataServices'
import { processHumanReadableDate } from '@/services/DateTimeServices.ts'

export default defineComponent({
  name: 'FaultsView',
  data() {
    return {
      updateKey: 0 as number
    }
  },
  methods: {
    async retrieveTableData(
      maxItems: number,
      startIndex: number,
      stringFilter?: string,
      orderBy?: string,
      // prettier-ignore
      filterObjectArray?: Filter[] // eslint-disable-line
    ): Promise<DataSet<LocalFault>> {
      if (orderBy) {
        if (orderBy.includes('order') && !orderBy.includes('order_item')) {
          orderBy = orderBy.replace('order', 'order_item__order_id')
        }
      }
      return api.getFaultsSet(maxItems, startIndex, stringFilter || undefined, orderBy || undefined)
    },
    // prettier-ignore
    processFieldFunction(fieldValue: any, columnName: string, row?: any) { // eslint-disable-line
      // Formats expected objects as readable string(s)
      if (['created', 'date', 'updated'].includes(columnName.toLowerCase())) {
        // Is date
        return processHumanReadableDate(fieldValue, true)
      }

      return fieldValue
    }
  },
  created() {
    // Update Users Local Variables On Change
    const updateTable = () => this.updateKey++
    createUpdatedFaultsListener(updateTable.bind(this))
  },
  components: {
    PageHeader,
    TableComponent
  }
})
</script>
