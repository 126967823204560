<template>
  <dialog-content
    :dialog-title="dialogTitle"
    :on-submit="saveFault"
    :buttons="[
      { label: 'Cancel', onClick: closeDialog },
      {
        label: 'Save',
        condition: editable,
        type: 'submit'
      }
    ]"
  >
    <!--  Fault Description: -->
    <text-field-input
      :label="'Fault Description'"
      v-model:text="description"
      :editable="editable"
      :required="true"
    />

    <!--  Fault Count  -->
    <div v-if="showCount" class="pt-4">
      <number-input
        :label="'Apply To Count: '"
        :number="itemStatusCount"
        @update:number="count = $event"
        :editable="true"
        :min="1"
        :max="itemStatusCount"
        :placeholder="itemStatusCount"
      />
    </div>
  </dialog-content>
</template>

<script lang="ts">
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { throwError } from '@/services/ErrorHandler'
import { emitFaultsUpdated, itemsUpdated, showSpinner } from '@/composables'
import TextFieldInput from '@/components/inputComponents/TextFieldInput.vue'
import { type FaultDialogObject, LocalOrderItem } from '@/services/DataServices'
import { setGlobalUpdatingFlag } from '@/main'
import NumberInput from '@/components/inputComponents/NumberInput.vue'

export default defineComponent({
  name: 'ViewFaultDialogContent',
  emits: ['update:dialogOpen'],
  props: {
    dialogOpen: {
      // Receives dialogOpen value from v-model:dialog-open="dialogOpen"
      type: Boolean,
      required: true,
      default: true
    },
    faultProp: {
      type: Object as PropType<object | null>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialogTitle() {
      return this.items
        ? 'Add Faults for ' + this.items.length + ' Order Items'
        : 'Add Fault for OrderItem ' + (this.item.id || '')
    },
    showCount(): boolean {
      return !this.items && this.item && this.itemStatusCount > 1
    },
    itemStatusCount(): number {
      return this.item?.count !== undefined ? +this.item.count : 1
    }
  },
  data() {
    return {
      item: {} as LocalOrderItem,
      items: undefined as LocalOrderItem[] | undefined,
      count: 0 as number,
      description: '' as string
    }
  },
  methods: {
    async saveFault() {
      if (this.items) {
        showSpinner(true, this.items.length)
        setGlobalUpdatingFlag(true)
        for (const item of this.items) {
          await item.logFault(this.description, item?.hasCount ? item.countAsNumber : undefined)
        }
        setGlobalUpdatingFlag(false)
      } else {
        showSpinner(true)
        await this.item.logFault(this.description, this.showCount ? this.count : undefined)
      }
      emitFaultsUpdated()
      showSpinner(false)
      this.closeDialog(this.item || this.items)
    },
    closeDialog(item?: LocalOrderItem | LocalOrderItem[]) {
      // Updates Users Local OrderItem Object
      if (item) itemsUpdated()
      this.$emit('update:dialogOpen', false)
    }
  },
  created() {
    // On page load (after DOM)
    try {
      if (Array.isArray((this.faultProp as FaultDialogObject).orderItem)) {
        this.items = (this.faultProp as FaultDialogObject).orderItem as LocalOrderItem[]
      } else {
        this.item = new LocalOrderItem(
          (this.faultProp as FaultDialogObject).orderItem as LocalOrderItem
        )
        this.count = this.item.count ? +this.item.count : 1
      }
    } catch (e) {
      throwError('Error in FaultDialog props.', e)
      this.closeDialog()
    }
  },
  components: {
    NumberInput,
    DialogContent,
    TextFieldInput
  }
})
</script>
