<template>
  <input-component-base
    :label="label"
    :name="name"
    :class="[
      $attrs.class,
      { ' pt-0 sm:pt-1': !editable && !slimStyling },
      { ' py-0.5 sm:py-0.25': editable && !slimStyling }
    ]"
    @resize="updateListPosition"
    :tooltip="tooltip"
    data-testid="list-input-test-id"
    :user-input-visible="editable"
    :id="$attrs.id"
    :slim-styling="slimStyling"
  >
    <div class="w-full" ref="ListInputParent">
      <div v-if="!editable" class="py-1.5 sm:py-0 sm:pb-[0.4rem]">
        <span class="cursor-text flex-1 w-full bg-white text-center text-gray-900 sm:text-sm">
          {{ getLabel(updatedSelected) }}
        </span>
      </div>
      <div
        v-if="editable"
        class="max-w-[1rem] sm:max-w-full min-w-full"
        :class="{ ' pointer-events-none opacity-50': loadingData }"
      >
        <div class="min-h-fit relative max-w-full" ref="ListBox">
          <Listbox
            as="div"
            :model-value="updatedSelected"
            @update:model-value="updateUpdateSelected($event)"
            :disabled="!editable"
          >
            <div
              tabindex="-1"
              class="relative"
              ref="SelectButton"
              :id="'SelectButton' + id"
              @keydown.enter="showHideDropDown('buttonEnter')"
              @click="hideDropDownOnly('buttonClick')"
            >
              <ListboxButton
                :name="name"
                :id="'ListboxButton' + id"
                @keydown.enter="showHideDropDown('buttonEnter')"
                @keydown.space="showHideDropDown('buttonSpace')"
                @keydown.esc="hideDropDown('buttonEsc')"
                @click="showHideDropDown('buttonClick')"
                @keydown.down="listBoxButtonUpDown('ListboxButtonDown')"
                @keydown.up="listBoxButtonUpDown('ListboxButtonUp')"
                :class="[
                  { 'py-0 sm:py-1': slimStyling && enableTextFilter },
                  { 'py-1.5 sm:py-0.5': slimStyling && !enableTextFilter },
                  { 'py-0 sm:py-1.5': !slimStyling && enableTextFilter },
                  { 'py-1.5 sm:py-1': !slimStyling && !enableTextFilter },
                  {
                    'sm:leading-6 shadow-sm ring-1 ring-inset ring-gray-300 sm:pr-10': editable
                  },
                  { 'shadow-none pl-0': !editable },
                  { 'cursor-text pl-1.5': enableTextFilter },
                  { 'pl-3': !enableTextFilter },
                  { 'sm:pointer-events-none': showDropDown }
                ]"
                class="hover:bg-gray-50 hover:text-black shadow-sm hover:shadow mr-6 block flex-1 w-full cursor-default rounded-md bg-white text-center text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm"
              >
                <span
                  v-if="!enableTextFilter"
                  class="select-none h-6 block truncate min-h-[1.5rem] pr-8 sm:pr-3"
                  >{{
                    loadingData
                      ? 'Loading...'
                      : customPlaceHolderFunction
                        ? customPlaceHolderFunction(getLabel(updatedSelected))
                        : getLabel(updatedSelected)
                  }}</span
                >
                <!-- prettier-ignore -->
                <form @submit.prevent> <!-- Forces virtual keyboard to display return/enter key when list exists in external form -->
                  <input
                    autocomplete="off"
                    v-if="enableTextFilter"
                    @click="unHideDropDown('listBoxClick', $event)"
                    :id="'textFilterInput' + id"
                    tabindex="-1"
                    :name="'textFilterInput' + id"
                    ref="textFilterInput"
                    type="text"
                    class="w-full sm:max-w-none truncate h-6 sm:h-5 rounded text-center block border-0 bg-transparent pr-3 focus:ring-0 sm:text-sm sm:leading-5 focus:shadow-inner focus:bg-gray-100 sm:focus:bg-gray-50 focus:text-left"
                    @input="filterList"
                    :placeholder="
                      customPlaceHolderFunction
                        ? customPlaceHolderFunction(getLabel(updatedSelected))
                        : getLabel(updatedSelected)
                    "
                    @focusout="unfocusTextInput($event)"
                    @keydown.esc="hideDropDown('textFilterInputEsc', $event)"
                    @keydown.enter="selectInputFilter()"
                    @keydown.space="$event.stopPropagation()"
                    :class="{
                      'w-full': !enableTextFilter,
                      'w-0': enableTextFilter,
                      'max-w-[calc(100%_-_2rem)] my-1.5 py-2 sm:py-0 sm:my-0 placeholder:text-gray-500':
                        showDropDown,
                      'py-[1.125rem] sm:py-0 placeholder:text-gray-900': !showDropDown
                    }"
                  />
                </form>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <component class="h-5 w-5" aria-hidden="true" :is="customIcon as object">
                  </component>
                </span>
              </ListboxButton>
            </div>
            <div
              style="height: 0; max-height: 0; pointer-events: none; opacity: 0; overflow: hidden"
            >
              <ul
                :tabindex="-1"
                aria-hidden="true"
                class="p-0 h-0 shadow-inner bg-white text-base sm:text-sm"
              >
                <li
                  v-for="(value, index) in limitedListOptions"
                  :key="'li' + index"
                  class="list-none h-0 max-h-0 min-h-0 py-0 my-0"
                  :class="['relative cursor-default select-none pl-3 pr-9']"
                >
                  <span class="text-left h-0 max-h-0 min-h-0 py-0 my-0 block truncate">{{
                    getLabel(value)
                  }}</span>
                  <span
                    v-if="selected"
                    :class="'absolute inset-y-0 right-0 flex items-center pr-4'"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ul>
            </div>
            <div
              id="dropDownListLocatorID"
              tabindex="-1"
              class="z-10 select-list rounded-lg"
              :class="[
                { 'sm:fixed': displayFixed },
                { 'sm:absolute': !displayFixed },
                { 'list-normal': !reverseDropDown },
                { 'list-reverse': reverseDropDown }
              ]"
              ref="DropDownOptions"
            >
              <expandable-content
                :max-height="maxHeight"
                :inner-style-override="'border-radius: 0.5rem;'"
                :hide-border="true"
                :over-ride-shown="showDropDown"
                class="sm:w-full"
                @update:reference-height="expandableReferenceHeight = $event"
                @scroll="preventClose()"
                @update:mouse-interaction="scrollClick($event)"
              >
                <listboxOptions
                  data-testid="listbox-options"
                  :tabindex="-1"
                  aria-hidden="{{ !showDropDown }}"
                  :static="true"
                  class="p-0 shadow-inner bg-white text-base sm:text-sm rounded-lg overflow-hidden focus-visible:outline-none"
                  @keydown.enter="hideDropDown('listboxOptionsEnter')"
                  @keydown.space="hideDropDown('listboxOptionsSpace')"
                  @keydown.esc="hideDropDown('listboxOptionsEsc')"
                  @focusout="unFocusListOptions($event)"
                  ref="listboxOptions"
                  :id="'listboxOptions' + id"
                  @click="clickListItem()"
                >
                  <ListboxOption
                    as="template"
                    v-for="(value, index) in limitedListOptions"
                    :key="JSON.stringify(value)"
                    :value="value"
                    v-slot="{ active, selected }"
                    :id="'LBON-' + index + '-LBO-' + id"
                  >
                    <li
                      :id="'LIN-' + index + '-LI-' + id"
                      class="capitalize list-none transition-colors ease-in-out duration-200"
                      :class="[
                        active
                          ? 'bg-indigo-600 text-white duration-0 hover:duration-0 hover:bg-indigo-600 hover:text-white'
                          : 'text-gray-900 hover:duration-0 hover:bg-indigo-600 hover:text-white',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      ]"
                    >
                      <span
                        class="text-left block truncate"
                        :class="[selected ? 'font-semibold' : 'font-normal']"
                        >{{ getLabel(value) }}</span
                      >
                      <span
                        v-if="selected"
                        class="transition-colors ease-in-out duration-200"
                        :class="[
                          active
                            ? 'text-white duration-0'
                            : ' hover:duration-0 hover:text-white text-indigo-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4'
                        ]"
                      >
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                  <div v-if="limitedListOptions.length === 0" class="p-2">
                    <span class="capitalize text-gray-400 font-normal block truncate">
                      No Items To Display...
                    </span>
                  </div>
                  <div v-if="excessOptions" class="p-3 pt-2">
                    <span class="capitalize text-gray-400 font-normal block truncate">
                      +{{
                        (totalOptionsCount || listOptions.length) - limitedListOptions.length || 0
                      }}
                      More Items...
                    </span>
                  </div>
                </listboxOptions>
              </expandable-content>
            </div>
          </Listbox>
        </div>
      </div>
    </div>
  </input-component-base>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'
import type { PropType } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { throwError } from '@/services/ErrorHandler'
import type { DataSet, EnumType } from '@/services/DataServices'
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/vue/24/outline'
import ExpandableContent from '@/components/ExpandableContent.vue'
import { createGlobalDocumentScrollListener, createResizeListener } from '@/composables'
import { getGlobalUpdatingFlag } from '@/main'
import { AbortablePromise } from '@/services/DataServices'
import InputComponentBase from '@/components/inputComponents/InputComponentBase.vue'

type ListRequest = (maxNumItems: number, stringFilter?: string) => Promise<DataSet<any>>

export default defineComponent({
  name: 'ListInput',
  components: {
    InputComponentBase,
    ListboxOption,
    ListboxOptions,
    ListboxButton,
    Listbox,
    ExpandableContent,
    CheckIcon
  },
  props: {
    updateKey: {
      type: [String, Number] as PropType<string | number | undefined | null>,
      required: false,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    selected: {
      type: [String, Number, Object, null, undefined] as PropType<
        string | number | object | null | undefined
      >,
      default: ''
    },
    options: {
      type: [Array, Function] as PropType<string[] | number[] | object[] | ListRequest>,
      required: true
    },
    objectLabelKeyName: {
      type: String as PropType<string | null>,
      default: ''
    },
    customLabelGetFunction: {
      type: Function as PropType<Function | null>,
      default: null
    },
    customPlaceHolderFunction: {
      type: Function as PropType<Function | null>,
      default: null
    },
    useEnumValuesAsLabels: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    enumKeyToLabelObjectArray: {
      type: Object as PropType<EnumType[]>,
      default: undefined
    },
    slimStyling: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    maxHeight: {
      default: '17rem',
      type: String
    },
    customIcon: {
      default: ChevronUpDownIcon,
      type: [Object, Function] as PropType<object | Function>
    },
    forceTextFilterShown: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined
    },
    maxListOptions: {
      type: Number as PropType<number>,
      default: 50
    },
    tooltip: {
      type: String,
      required: false
    },
    name: {
      value: String,
      default: 'list-input'
    }
  },
  computed: {
    limitedListOptions() {
      return this.excessOptions ? this.listOptions.slice(0, this.maxListOptions) : this.listOptions
    },
    excessOptions() {
      return this.enableTextFilter
        ? (this.totalOptionsCount || this.listOptions.length) >= this.maxListOptions
        : false
    },
    enableTextFilter() {
      if (this.forceTextFilterShown === undefined) {
        return (this.totalOptionsCount || this.options?.length) > 30
      }
      return this.forceTextFilterShown
    }
  },
  data() {
    return {
      updatedSelected: '' as string | number | object,
      showDropDown: false,
      reverseDropDown: false,
      listParentWidth: '0' as string,
      slctBtnPrvDstFrmTop: 0 as number,
      expandableReferenceHeight: 0 as number,
      listOffset: '0' as string, // listOffset is used to align the list with the listbutton when floating (desktop mode)
      listLeftOffset: '0' as string,
      listOptions: [] as string[] | number[] | object[],
      id: 0 as number,
      displayFixed: false as boolean, // display:fixed is resource intensive and should be applied only when required
      processing: false as boolean,
      totalOptionsCount: null as number | null,
      loadingData: false as boolean,
      abortablePromise: new AbortablePromise()
    }
  },
  methods: {
    unFocusListOptions(event: any) {
      const focusTargetID: string | undefined = event.relatedTarget?.id
      const newTargetIsButton: boolean =
        focusTargetID?.includes('SelectButton' + this.id) ||
        focusTargetID?.includes('-LBO-' + this.id) ||
        focusTargetID?.includes('-LI-' + this.id) ||
        false
      if (!this.enableTextFilter && !newTargetIsButton) {
        this.unFocus('listOptionsUnfocus')
      }
    },
    unfocusTextInput(event: any) {
      const focusTargetID: string | undefined = event.relatedTarget?.id
      const newTargetIsOption: boolean =
        focusTargetID?.includes('-LBO-' + this.id) ||
        focusTargetID?.includes('-LI-' + this.id) ||
        false
      if (!newTargetIsOption) {
        this.unFocus('listTextInputUnfocus')
      }
    },
    async unFocus(source?: string) {
      if (!this.processing && this.showDropDown) {
        this.hideDropDown(source)
      }
    },
    showHideDropDown(source: string = '') {
      if (this.showDropDown) {
        this.hideDropDown(source)
      } else {
        this.unHideDropDown(source)
      }
    },
    hideDropDownOnly(source: string = '') {
      if (this.showDropDown) {
        this.setShowList(false, source)
      }
    },
    clickListItem() {
      if (this.showDropDown) {
        this.awaitNextTick().then(() => this.setShowList(false, 'listboxOptionsClick'))
      }
    },
    hideDropDown(source: string = '', event?: Event) {
      event?.stopPropagation() // Prevents closing listbox with escape closing the dialog etc.
      this.setShowList(false, source)
    },
    unHideDropDown(source: string = '', event?: Event) {
      event?.stopPropagation() // allows webkit to focus input, otherwise async programatic focusing won't bring up virtual keyboard
      this.setShowList(true, source)
    },
    async scrollClick(event: string) {
      if (event === 'down') {
        this.processing = true
      } else {
        if (this.enableTextFilter) {
          await this.focusTextInput()
        }
        this.processing = false
      }
    },
    listBoxButtonUpDown(source: string = '') {
      if (this.showDropDown) {
        this.preventClose()
      } else {
        this.unHideDropDown(source)
      }
    },
    preventClose() {
      if (this.showDropDown && !this.processing) {
        this.processing = true
        this.awaitNextTick().then(() => {
          this.processing = false
        })
      }
    },
    async setDisplayAbsolute() {
      let size: number = Number.MAX_SAFE_INTEGER
      for (let i = 0; i < 99; i++) {
        // Wait for list close animation to finish
        const list: HTMLDivElement = this.$refs.DropDownOptions as HTMLDivElement
        if (list) {
          if (list?.offsetHeight === 0) {
            await this.awaitNextTick(3)
            if (!this.showDropDown) {
              this.displayFixed = false
            }
            break // List is hidden
          } else if (size + 30 <= list?.offsetHeight || this.showDropDown) {
            break // List is being displayed
          }
          size = list?.offsetHeight || size
          await this.awaitNextTick()
        }
      }
    },
    getLabel(value: string | number | object): string {
      if (this.customLabelGetFunction) {
        try {
          return this.customLabelGetFunction(value) as string
        } catch (e) {
          throwError(
            'List Input Error, Improper Label Get Function Called.',
            'Label = ' + JSON.stringify(this.objectLabelKeyName)
          )
        }
      } else if (typeof value === 'object') {
        try {
          return value[this.objectLabelKeyName as keyof typeof value]
        } catch (e) {
          throwError(
            'List Input Error, Label Key Name probably not set.',
            'Label = ' + JSON.stringify(this.objectLabelKeyName)
          )
        }
      } else if (this.enumKeyToLabelObjectArray) {
        return ((this.enumKeyToLabelObjectArray as EnumType[]).find(
          (element: EnumType) => element.type === value
        )?.label || '') as string
      }
      return typeof value === 'object'
        ? Object.prototype.toString === value.toString
          ? value.toString()
          : JSON.stringify(value)
        : '' + value
    },
    updateListPosition() {
      if (this.$refs.DropDownOptions && this.$refs.SelectButton) {
        //Note: getBoundingClientRect values are relative to targets viewport so offsets must be calculated
        const selectButtonRef = (this.$refs.SelectButton as any)?.getBoundingClientRect()
        const selectList = (this.$refs.DropDownOptions as any)?.getBoundingClientRect()
        const currentListOffset = this.listOffset.includes('px')
          ? parseFloat(this.listOffset.replace('px', ''))
          : 0
        const crntListLeftOfst = this.listLeftOffset.includes('px')
          ? parseFloat(this.listLeftOffset.replace('px', ''))
          : 0
        this.listLeftOffset = crntListLeftOfst - (selectList.left - selectButtonRef.left) + 'px'
        if (this.reverseDropDown) {
          this.listOffset = currentListOffset - (selectButtonRef.top - selectList.bottom) + 'px'
        } else {
          this.listOffset = currentListOffset - (selectList.top - selectButtonRef.bottom) + 'px'
        }
      }
    },
    async setShowList(show: boolean, source?: string) {
      if (!this.processing) {
        this.processing = true
        this.showDropDown = show
        nextTick().then(() =>
          nextTick().then(() =>
            nextTick().then(() => {
              this.updateDimensions()
              nextTick().then(this.updateDimensions)
            })
          )
        )
        if (show && this.showDropDown && this.enableTextFilter) {
          this.filterList()
        }
        if (
          !this.showDropDown &&
          this.enableTextFilter &&
          (source?.toLowerCase().includes('esc') || source === 'scrollMove')
        ) {
          await this.removeTextInputFocus()
        }
        if (this.enableTextFilter && show) {
          await this.focusTextInput()
        }
        if (
          !show &&
          (source?.toLowerCase().includes('esc') || source?.toLowerCase().includes('enter'))
        ) {
          await this.awaitNextTick()
          document.getElementById('ListboxButton' + this.id)?.focus()
        }
        await this.awaitNextTick()
        this.processing = false
      }
    },
    async focusTextInput() {
      for (let i = 0; i < 30; i++) {
        await this.awaitNextTick(2)
        ;(this.$refs.textFilterInput as HTMLInputElement).focus()
        await this.awaitNextTick()
        if (document.activeElement === (this.$refs.textFilterInput as HTMLInputElement)) {
          break
        }
      }
    },
    async removeTextInputFocus() {
      for (let i = 0; i < 30; i++) {
        await this.awaitNextTick(2)
        ;(this.$refs.textFilterInput as HTMLInputElement).blur()
        await this.awaitNextTick()
        if (document.activeElement !== (this.$refs.textFilterInput as HTMLInputElement)) {
          break
        }
      }
    },
    async awaitNextTick(times?: number) {
      for (let i = 0; i < (times && times > 0 ? times : 1); i++) {
        await new Promise<void>((resolve) =>
          setTimeout(() => {
            nextTick(() => resolve())
          }, 1)
        )
      }
    },
    updateDimensions() {
      this.reverseDropDown = this.shouldReverseDropDownDirection()
      this.slctBtnPrvDstFrmTop = (this.$refs.SelectButton as any)?.getBoundingClientRect().top
      this.listParentWidth = (this.$refs.ListInputParent as any)?.offsetWidth + 'px'
      this.updateListPosition()
    },
    shouldReverseDropDownDirection(): boolean {
      const listTopDistance: number =
        (this.$refs.ListBox as any)?.getBoundingClientRect().top + window.scrollY
      const listBaseDistance: number = window.innerHeight - listTopDistance
      const buttonHeight: number = (this.$refs.SelectButton as any)?.offsetHeight
      const ListHeight: number = this.expandableReferenceHeight + buttonHeight
      return listBaseDistance < ListHeight && listTopDistance - buttonHeight > listBaseDistance
    },
    filterList(event?: Event) {
      if (!this.showDropDown) {
        this.setShowList(true, 'filterList')
      }
      const inputValue: string = event ? (event.target as HTMLInputElement).value : ''
      if (typeof this.options === 'function' && !Array.isArray(this.options)) {
        this.loadData(() => {}, inputValue, false)
      } else {
        this.listOptions = (this.options as Array<any>).filter((option: any) => {
          const label = this.getLabel(option)
          return label
            .toLowerCase()
            .replace(/[_ -]/g, '')
            .includes(inputValue.toLowerCase().replace(/[_ -]/g, ''))
        })
      }
    },
    selectInputFilter() {
      if (this.listOptions.length > 0 && this.updatedSelected !== this.listOptions[0]) {
        this.updatedSelected = this.listOptions[0]
      } else {
        ;(this.$refs.textFilterInput as any).value = this.selected
      }
    },
    initializeOptions() {
      if (typeof this.options === 'function' && !Array.isArray(this.options)) {
        this.loadData(() => {
          this.updatedSelected =
            !this.selected || JSON.stringify(this.selected) === '{}'
              ? this.listOptions[0]
              : this.selected
        })
      } else {
        this.updatedSelected =
          !this.selected || JSON.stringify(this.selected) === '{}' ? this.options[0] : this.selected
      }
    },
    loadData(then?: Function, stringFilter?: string, disableOnLoad: boolean = true) {
      if (typeof this.options === 'function' && !Array.isArray(this.options)) {
        this.loadingData = disableOnLoad
        const func = async () => (this.options as Function)(this.maxListOptions, stringFilter || '')
        const next = (response: DataSet<any>) => {
          ;(this.options as Function)(1, '').then((response2: DataSet<any>) => {
            this.totalOptionsCount = response2.totalNum - response2.data.length || null
          })
          this.listOptions = response.data
          this.updateDimensions()
          this.loadingData = false
          if (then) {
            then()
          }
        }
        this.abortablePromise.resetAndExecute(func, next)
      }
    },
    updateUpdateSelected(eventValue: any) {
      if (JSON.stringify(eventValue) !== JSON.stringify(this.updatedSelected)) {
        this.updatedSelected = eventValue
      }
    }
  },
  created() {
    this.id = parseInt(Date.now().toString() + Math.round(Math.random() * 10000).toString())
    this.initializeOptions()
    this.updateDimensions()

    const resizeCallback = () => this.updateDimensions()
    createResizeListener(resizeCallback.bind(this))

    const scrollCallback = () => {
      this.awaitNextTick(1).then(() => {
        if (this.showDropDown) {
          const width: number = window.innerWidth ? window.innerWidth : screen.width || 0
          if (this.$refs.SelectButton && width >= 640) {
            const drpDwnBtm = (this.$refs.DropDownOptions as any).getBoundingClientRect().bottom
            const drpDwnTp = (this.$refs.DropDownOptions as any).getBoundingClientRect().top
            const slctBtnBtm = (this.$refs.SelectButton as any).getBoundingClientRect().bottom
            const slctBtnTp = (this.$refs.SelectButton as any).getBoundingClientRect().top
            const slctBtnLft = (this.$refs.SelectButton as any).getBoundingClientRect().left
            const dropDownLeft = (this.$refs.DropDownOptions as any).getBoundingClientRect().left
            const maxErr = 0.01
            const rLstCorctPos = drpDwnBtm - slctBtnTp <= maxErr && drpDwnBtm - slctBtnTp >= -maxErr
            const listCorctPos = drpDwnTp - slctBtnBtm <= maxErr && drpDwnTp - slctBtnBtm >= -maxErr
            if (
              (this.reverseDropDown && !rLstCorctPos) ||
              (!this.reverseDropDown && !listCorctPos)
            ) {
              this.setShowList(false, 'scrollMove')
            } else if (this.slctBtnPrvDstFrmTop !== slctBtnTp || slctBtnLft !== dropDownLeft) {
              this.slctBtnPrvDstFrmTop = slctBtnTp
              this.setShowList(false, 'scrollMove')
            }
          }
        }
        this.updateDimensions() // Must occur after correct pos or previous button pos will equal button pos
      })
    }
    createGlobalDocumentScrollListener(scrollCallback.bind(this))
  },
  mounted() {
    this.updateDimensions()
  },
  watch: {
    updatedSelected: {
      handler(newValue) {
        if (newValue && newValue !== this.selected) {
          this.$emit('update:selected', newValue) // for v-model:selected="boundValue" and @update:selected="boundValue = $event"
        }
      }
    },
    showDropDown: {
      handler(newValue) {
        if (newValue === true) {
          nextTick().then(() => {
            this.displayFixed = true
          })
        } else {
          this.setDisplayAbsolute().then()
        }
        if (this.$refs?.textFilterInput) {
          ;(this.$refs.textFilterInput as HTMLInputElement).value = ''
        }
      }
    },
    options: {
      handler() {
        if (typeof this.options === 'function' && !Array.isArray(this.options)) {
          this.loadData()
        } else if (this.options) {
          this.totalOptionsCount = null
          this.listOptions = this.options
          this.updateDimensions()
        }
      },
      deep: true,
      immediate: true
    },
    selected: {
      handler(newValue) {
        if (newValue) {
          this.updatedSelected = newValue
        }
      }
    },
    updateKey: {
      handler: async function (newVal, oldVal) {
        if (!getGlobalUpdatingFlag() && newVal !== undefined && newVal !== oldVal) {
          this.initializeOptions()
        }
      },
      deep: true,
      immediate: true
    }
  }
})
</script>

<style>
.select-list {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --width: v-bind(listParentWidth);
  max-width: var(--width);
}

@media (min-width: 640px) {
  .list-reverse {
    --offset: v-bind(listOffset);
    --left-offset: v-bind(listLeftOffset);
    bottom: var(--offset);
    left: var(--left-offset);
    position: absolute;
    --tw-shadow: 0 -20px 25px -2px rgb(0 0 0 / 0.1), 0 -8px 10px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored:
      0 -20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow:
      var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .list-normal {
    --offset: v-bind(listOffset);
    --left-offset: v-bind(listLeftOffset);
    top: var(--offset);
    left: var(--left-offset);
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored:
      0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow:
      var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .select-list {
    --width: v-bind(listParentWidth);
    min-width: var(--width);
  }
}
</style>
