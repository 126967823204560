<template>
  <tr
    v-if="displayRowGroups"
    class="cursor-pointer w-full text-left table-row bg-slate-600/[0.1] hover:bg-slate-600/[0.2]"
    ref="row"
  >
    <!-- SELECT GROUP OF ROWS CHECKBOX -->
    <table-cell
      class="text-center mr-1 w-0 sm:text-center capitalize cursor-pointer hover:bg-slate-600/[0.1] whitespace-break-spaces"
      v-if="displayCheckBoxes"
      @click="selectGroup()"
    >
      <input
        type="checkbox"
        class="p-[clamp(0.6rem,_3vw,_1rem)] sm:p-0 m-0 sm:m-2 sm:m0 cursor-pointer left-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        :checked="groupSelected"
        @change="selectGroup($event)"
      />
    </table-cell>

    <!-- GROUP OF ROWS HEADER -->
    <td
      @click="showHideRows"
      colspan="100%"
      class="whitespace-nowrap bg-transparent capitalize p-2 text-xs sm:text-sm font-semibold text-gray-700"
    >
      <PlusIcon
        v-if="collapsed"
        class="text-gray-400 inline-flex itemChevron h-6 w-6"
        aria-hidden="true"
      />
      <MinusIcon
        v-if="!collapsed"
        class="text-gray-400 inline-flex itemChevron h-6 w-6"
        aria-hidden="true"
      />
      <component
        class="ml-3"
        v-if="showIcon"
        :is="collapsableIcon?.iconComponent"
        :class="collapsableIcon?.iconClass ?? 'w-6 h-6 inline-block text-neutral-500'"
        v-tooltip="iconTooltip"
      />
      <div class="inline-flex">
        <span
          class="block sm:max-w-none min-w-32 truncate"
          :class="
            showIcon ? 'pl-3 max-w-[calc(100dvw_-_10rem)]' : 'pl-12 max-w-[calc(100dvw_-_7rem)]'
          "
        >
          {{ collapsableRowText }}
        </span>
      </div>
    </td>
  </tr>
  <slot v-if="!collapsed"></slot>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { PlusIcon, MinusIcon } from '@heroicons/vue/20/solid'
import TableCell from '@/components/Table/TableCell.vue'
import { CollapsableRowGroupIcon } from '@/services/DataServices'

export default defineComponent({
  name: 'CollapsableRowGroups',
  emits: ['update:showGroup', 'update:selectGroup'],
  components: { TableCell, PlusIcon, MinusIcon },
  props: {
    displayRowGroups: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    tableRows: {
      type: Array as PropType<any[]>,
      required: true
    },
    groupBy: {
      type: String as PropType<string | null>,
      required: true
    },
    groupByValue: {
      type: String as PropType<string>,
      required: true
    },
    collapsed: {
      type: Boolean as PropType<boolean>,
      required: false
    },
    displayCheckBoxes: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    groupSelected: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    collapsableIcon: {
      type: Object as PropType<CollapsableRowGroupIcon>
    }
  },
  computed: {
    collapsableRowText(): string {
      return (
        this.tableRows.length +
        (this.tableRows.length > 1 ? ' Rows' : ' Row') +
        ' With ' +
        (this.groupBy || 'Property') +
        (this.groupByValue.length < 1 ? ' Not Set' : ' Equal To "' + this.groupByValue + '"')
      )
    },
    showIcon(): boolean {
      return this.collapsableIcon?.displayCheck && this.collapsableIcon.displayCheck(this.tableRows)
    },
    iconTooltip(): string {
      return this.collapsableIcon?.getToolTip ? this.collapsableIcon.getToolTip(this.tableRows) : ''
    }
  },
  methods: {
    showHideRows() {
      // if group is collapsed/hidden (collapsed == true) then showGroup(collapsed) will set group to shown
      this.$emit('update:showGroup', this.collapsed)
    },
    selectGroup(event?: Event) {
      if (event) {
        this.$emit('update:selectGroup', (event.target as HTMLInputElement).checked)
      } else {
        this.$emit('update:selectGroup', !this.groupSelected)
      }
    }
  }
})
</script>
