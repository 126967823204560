<template>
  <dialog-content
    :dialog-title="dialogTitle"
    :on-submit="submitOrder"
    :buttons="[
      { label: 'Close', onClick: closeDialog },
      {
        label: 'Cancel Order',
        onClick: cancelDialogOrder,
        condition:
          !editable &&
          dialogOrder?.status !== OrderStatusEnum.Cancelled &&
          dialogOrder?.status !== OrderStatusEnum.Complete
      },
      {
        label: 'Review Order',
        onClick: runUserReview,
        condition: dialogOrder?.status === OrderStatusEnum.UserReview && !userReview
      },
      { label: 'Add New SKU', onClick: openNewSkuDialog, condition: editable },
      {
        label: userReview ? 'Save Order and Complete Review' : 'Save',
        type: 'submit',
        condition: editable || (editableFields || [])?.length > 0 || userReview
      },
      {
        label: 'Mark Packed',
        onClick: markOrderComplete,
        condition: dialogOrder?.status === OrderStatusEnum.ReadyForDispatch,
        tooltip: 'Mark Order as Packed and Complete.'
      }
    ]"
  >
    <!--  Customer Name: -->
    <text-input
      required
      :label="'Customer Name'"
      v-model:text="dialogOrder.customer_name"
      :editable="editable || isEditable('customer_name') || userReview"
    />
    <!--  Order Notes: -->
    <text-field-input
      :label="'Order Notes'"
      v-model:text="dialogOrder.notes"
      :editable="editable || isEditable('notes') || userReview"
    />
    <!-- Import Source: -->
    <text-input
      v-if="(!editable || userReview) && dialogOrder?.order_import_source"
      required
      :label="'Order Import Source'"
      :text="dialogOrder.order_import_source?.name || ''"
      :editable="false"
    />
    <!--  Source Order ID: -->
    <text-input
      v-if="(!editable && dialogOrder.source_order_display_id) || userReview"
      required
      :label="'Source Order ID'"
      :text="dialogOrder.source_order_display_id ? dialogOrder.source_order_display_id : ''"
      @update:text="dialogOrder.source_order_display_id = $event"
      :editable="editable || isEditable('source_order_display_id')"
    />
    <!--  View Source Order Link: -->
    <button-input
      v-if="(!editable || userReview) && dialogOrder?.open_in_source_system_url"
      :action="viewSourceOrder"
      :button-text="'View Source Order'"
      :label="'Source Order'"
    />
    <!--  Status: -->
    <status-read-only-input
      v-if="dialogOrder.id && !userReview"
      :label="'Order Status'"
      :status="dialogOrder.status"
    />
    <!--  Date: -->
    <date-input
      v-if="editable || userReview"
      required
      :editable="editable || userReview"
      :label="'Order Date'"
      :date-text="dialogOrder.date"
      @update:date-text="dialogOrder.date = $event"
    />
    <text-input
      v-if="!editable && !userReview"
      :editable="editable"
      :label="'Order Date'"
      :text="processHumanReadableDate(dialogOrder.date, true)"
    />
    <!--  Date Created: -->
    <text-input
      v-if="!editable && !userReview"
      :label="'Date Created'"
      :text="processHumanReadableDate(dialogOrder.created, true)"
    />
    <!--  Last Updated: -->
    <text-input
      v-if="!editable && !userReview"
      :label="'Last Updated'"
      :text="processHumanReadableDate(dialogOrder.updated)"
    />

    <!-- Order Items: -->
    <div class="bottom-divider" />
    <div>
      <div class="flex items-center pb-4 flex-wrap sm:flex-nowrap sm:relative">
        <h2
          class="flex px-4 mx-auto w-full justify-center sm:w-auto text-base text-center font-semibold leading-7 text-gray-900 pb-4 sm:pb-0"
        >
          Order Items{{
            dialogOrderItems && dialogOrderItems.length > 1
              ? ' (' + dialogOrderItems.length + '):'
              : ''
          }}
        </h2>

        <button
          v-if="dialogOrderItems.length > 1 && expandedItemTabs.length === dialogOrderItems.length"
          data-testid="expand-collapse-all-items-test-id"
          type="button"
          v-tooltip="(allItemsExpanded ? 'Collapse' : 'Expand') + ' All Item Tabs'"
          class="sm:absolute text-center w-full justify-center sm:w-auto flex text-gray-700 items-center hover:bg-gray-50 hover:text-black cursor-pointer shadow hover:shadow-md h-9 sm:h-7 px-4 sm:px-4 rounded-md bg-white text-sm font-semibold ring-1 ring-inset ring-gray-300"
          @click="expandCollapseAllItemTabs()"
        >
          {{ (allItemsExpanded ? 'Collapse' : 'Expand') + ' All' }}
        </button>
      </div>

      <div
        v-if="(userReview && dialogOrder.status === OrderStatusEnum.UserReview) || editable"
        class="w-full flex justify-center pb-4"
      >
        <button
          data-testid="add-item-button"
          :disabled="loading"
          type="button"
          id="addOrderItemButton"
          v-tooltip="loading ? 'Loading Options...' : 'Add Order Item'"
          class="sm:col-span-2 p-2 sm:p-1 flex w-12 h-12 sm:h-8 sm:w-8 text-gray-600 rounded-3xl"
          :class="
            loading
              ? 'opacity-50 shadow-[0_0_0_1.5px_rgba(0,0,0,0.4)]'
              : 'cursor-pointer hover:text-gray-950 hover:bg-slate-600/[0.1] shadow-[1px_1px_4px_0_rgba(0,0,0,0.4)]'
          "
          @click="addOrderItem()"
        >
          <document-plus-icon
            style="padding-left: 1px; padding-bottom: 1px"
            class="h-full w-full"
            aria-hidden="true"
          />
        </button>
      </div>

      <div
        v-for="(orderItem, index) in dialogOrderItems.slice().reverse() as LocalOrderItem[]"
        :key="orderItem.id + '' + orderItem.updated || ''"
      >
        <div class="flex gap-1 sm:gap-4 w-full">
          <button
            data-testid="remove-item-button"
            type="button"
            v-tooltip="
              orderItem.isNew
                ? 'Remove Item'
                : orderItem.isAbleToBeCanceled
                  ? userReview
                    ? 'Mark Item For Cancelation'
                    : 'Cancel Item'
                  : dialogOrder.getItem(orderItem.id)?.isFinalized
                    ? 'Item Already Finalized'
                    : 'Undo Cancel Item'
            "
            class="min-w-[2rem] min-h-[2rem] cursor-pointer self-center p-0 sm:p-1 flex w-9 h-8 text-gray-600 rounded-3xl"
            :class="[
              { 'opacity-25': orderItem.isFinalized },
              {
                'hover:text-gray-950 hover:bg-slate-600/[0.1]':
                  !orderItem.isFinalized || !dialogOrder.getItem(orderItem.id)?.isFinalized
              }
            ]"
            @click="cancelItemClick(orderItem)"
          >
            <trash-icon
              style="padding-left: 1px; padding-bottom: 1px"
              class="h-full w-full"
              aria-hidden="true"
            />
          </button>
          <div
            class="w-full max-w-[max(12rem,_calc(100dvw_-_3.5rem))] sm:max-w-none overflow-hidden"
          >
            <expandable-content
              class="rounded-lg sm:rounded-2xl overflow-hidden m-1 shadow-[1px_1px_4px_0_rgba(0,0,0,0.2)] sm:min-w-[32.4rem]"
              :label="getOrderItemLabel(orderItem)"
              :label-icon-component="
                !orderItem.order
                  ? {
                      after: 'NEW',
                      class: '',
                      textClass:
                        'text-lime-600 italic font-semibold self-center text-sm drop-shadow-[0_0_4px_#a8ff00b3] w-9 pl-2 sm:pl-4 sm:mr-[-3rem]',
                      placeBefore: true
                    }
                  : orderItem.hasStatusSkuResolutionFailed
                    ? {
                        iconComponent: ExclamationTriangleIcon,
                        tooltip: 'Item Requires SKU Resolution',
                        class: 'text-red-700 drop-shadow-[0_0_4px_#b118157d]',
                        textClass: 'w-6 sm:w-12 h-6 sm:pl-2 sm:mr-[-3rem]',
                        placeBefore: true
                      }
                    : undefined
              "
              :expanded-by-default="userReview && !orderItem.isFinalized"
              :minimal-padding="userReview"
              :override-transition-time-in-ms="150"
              @expanded="updateExpanded(orderItem, $event)"
            >
              <order-item-inner-content-component
                :item="orderItem"
                @update:item="dialogOrderItems[dialogOrderItems.length - index - 1] = $event"
                :editable="editable || !orderItem.order"
                :parent-order="dialogOrder"
                :order-user-review="userReview"
                :enable-internal-buttons="true"
                :show-pre-stocked-checkbox="
                  userReview &&
                  dialogSkusList.find((s) => s.id === orderItem.sku)?.sku_type ===
                    SkuSkuTypeEnum.Manufactured &&
                  dialogSkusList.find((s) => s.id === orderItem.sku)?.design_type ===
                    SkuDesignTypeEnum.Static
                "
                @update:pre-stocked-item="setItemPreStocked($event as ItemPrestocked)"
              />
              <div
                data-testid="prestocked-item-note"
                v-if="preStockedItems.map((i) => i.item.id).includes(orderItem.id)"
                class="inline-block max-w-md mb-3 mt-0 text-center text-gray-700 text-sm font-medium leading-6"
              >
                <p
                  v-if="
                    !!dialogSkusList.find((s) => s.id === orderItem.sku)?.requires_post_processing
                  "
                >
                  Order Item will sent directly to Post Processing.
                </p>
                <p
                  v-if="
                    !dialogSkusList.find((s) => s.id === orderItem.sku)?.requires_post_processing
                  "
                >
                  Order Item will sent directly to Ready For Dispatch.
                </p>
              </div>
            </expandable-content>
          </div>
        </div>
      </div>
    </div>
  </dialog-content>
</template>

<script lang="ts">
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import {
  OrderStatusEnum,
  SkuDesignTypeEnum,
  SkuSkuTypeEnum,
  type OrderOrderItemsInner
} from '@/api'
import { throwError } from '@/services/ErrorHandler'
import {
  createUpdateditemListener,
  orderUpdated,
  showSpinner,
  runFunctionWithLoadingSpinner,
  createUpdateditemsListener,
  createUpdatedOrderListener
} from '@/composables'
import TextInput from '@/components/inputComponents/TextInput.vue'
import TextFieldInput from '@/components/inputComponents/TextFieldInput.vue'
import ExpandableContent from '@/components/ExpandableContent.vue'
import {
  api,
  LocalOrder,
  LocalOrderItem,
  LocalSku,
  type OrderDialogObject,
  getInitialSku,
  LocalOrderItemStatusEnum,
  ExpandableTab,
  CancelItemCountReturnType,
  AlertMessage
} from '@/services/DataServices'
import { DocumentPlusIcon, ExclamationTriangleIcon, TrashIcon } from '@heroicons/vue/24/outline'
import OrderItemInnerContentComponent from '@/components/OrderItemInnerContentComponent.vue'
import DateInput from '@/components/inputComponents/DateInput.vue'
import StatusReadOnlyInput from '@/components/inputComponents/StatusReadOnlyInput.vue'
import { setGlobalUpdatingFlag } from '@/main'
import { MessageTypes } from '@/components/AlertMessageComponent.vue'
import { processHumanReadableDate } from '@/services/DateTimeServices.ts'
import {
  confirmCancelStatusCountDialog,
  confirmDialog,
  newErrorDialog,
  newSkuDialog,
  newViewOrderDialog
} from '@/modal_dialogs/dialog-helpers.ts'
import ButtonInput from '@/components/inputComponents/ButtonInput.vue'

type ItemPrestocked = {
  item: LocalOrderItem
  prestocked: boolean
  count?: number
}

export default defineComponent({
  name: 'ViewOrderDialogContent',
  emits: ['update:dialogOpen'],
  computed: {
    SkuDesignTypeEnum() {
      return SkuDesignTypeEnum
    },
    SkuSkuTypeEnum() {
      return SkuSkuTypeEnum
    },
    LocalSku() {
      return LocalSku
    },
    LocalOrderItem() {
      return LocalOrderItem
    },
    OrderStatusEnum() {
      return OrderStatusEnum
    },
    dialogTitle() {
      const id: number | undefined = this.dialogOrder?.id || undefined
      return id ? 'Order ' + id + (this.userReview ? ' Review' : ' Details') : 'New Order'
    },
    allItemsExpanded(): boolean {
      return this.expandedItemTabs.every((tab) => tab.expanded)
    }
  },
  props: {
    dialogOpen: {
      type: Boolean,
      required: true
    },
    orderProp: {
      type: Object as PropType<object | null>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    editableFields: {
      type: Array as PropType<string[] | null>,
      default: () => ['notes', 'custom_text', 'post_processing_notes']
    }
  },
  data() {
    return {
      dialogOrder: {} as LocalOrder,
      dialogSkusList: [] as LocalSku[],
      dialogOrderItems: [] as LocalOrderItem[],
      userReview: false as boolean,
      initialSku: undefined as LocalSku | undefined,
      loading: false as boolean,
      preStockedItems: [] as { item: LocalOrderItem; count: number }[],
      expandedItemTabs: [] as ExpandableTab[]
    }
  },
  methods: {
    viewSourceOrder() {
      if (this.dialogOrder?.open_in_source_system_url)
        window.open(this.dialogOrder?.open_in_source_system_url, '_blank')
    },
    updateExpanded(item: LocalOrderItem, expanded: ExpandableTab) {
      const newTab = new ExpandableTab(expanded.expanded, expanded.setShown, item.id)
      this.expandedItemTabs = this.expandedItemTabs.filter((tab) => !tab.hasIndex(newTab.index))
      this.expandedItemTabs.push(newTab)
    },
    ExclamationTriangleIcon,
    processHumanReadableDate,
    async cancelDialogOrder() {
      const verify = await confirmDialog(
        'Cancel Order?',
        'Are you sure you want to cancel order ' + this.dialogOrder.id + '?'
      )
      if (verify) {
        showSpinner(true)
        try {
          this.dialogOrder = await this.dialogOrder.cancel()
          this.closeDialog()
        } catch (error) {
          throwError('Error Canceling order', error)
        }
        showSpinner(false)
      }
    },
    runUserReview() {
      this.closeDialog()
      newViewOrderDialog({ order: this.dialogOrder, userReview: true })
    },
    openNewSkuDialog() {
      newSkuDialog({} as LocalSku)
    },
    async markOrderComplete() {
      const completeOrderFunction = async () => await this.dialogOrder.complete()
      await runFunctionWithLoadingSpinner(completeOrderFunction.bind(this))
      this.closeDialog()
    },
    expandCollapseAllItemTabs(): void {
      this.expandedItemTabs.forEach((tab) => {
        if (tab.expanded === this.allItemsExpanded) tab.setShown()
      })
    },
    addOrderItem() {
      if (!this.initialSku) {
        throwError(
          'Error Adding Order Item',
          'No SKUs Exist in the system. \nPlease add any required SKUs first.'
        )
        return
      }
      try {
        if (!this.dialogOrderItems) this.dialogOrderItems = []
        this.dialogOrderItems.push(
          new LocalOrderItem({
            id: this.getTemporaryLocalID(),
            sku: this.initialSku?.id,
            count: 1
          })
        )
      } catch (error) {
        throwError('Error Adding Order Item', error)
      }
    },
    getTemporaryLocalID(): number {
      return parseInt(Date.now().toString() + Math.round(Math.random() * 10000).toString())
    },
    async cancelItemClick(item: LocalOrderItem) {
      this.removeItemFromPrestockedItems(item)
      if (item.isNew) this.removeNewOrderItem(item)
      else this.cancelExistingOrderItem(item)
    },
    removeNewOrderItem(orderItem: LocalOrderItem) {
      this.dialogOrderItems?.splice(this.dialogOrderItems.indexOf(orderItem), 1)
      const itemIndex: number = this.expandedItemTabs.findIndex((tab) => tab.index === orderItem.id)
      if (itemIndex > -1) this.expandedItemTabs?.splice(itemIndex, 1)
    },
    unCancelOrderItemInUserReview(item: LocalOrderItem) {
      try {
        this.revertOrderItemStatus(item)
      } catch (error) {
        throwError(error, 'Error Updating Order Item For User Review')
      }
    },
    revertOrderItemStatus(item: LocalOrderItem) {
      const oldStatus: LocalOrderItemStatusEnum = this.getOriginalItemStatusOrUserReview(item)
      const dialogOrderItem: LocalOrderItem | undefined = this.dialogOrder.getItem(item.id)
      if (dialogOrderItem) dialogOrderItem.setOnlyCountStatus(oldStatus)
      item.setOnlyCountStatus(oldStatus)
    },
    getOriginalItemStatusOrUserReview(item: LocalOrderItem): LocalOrderItemStatusEnum {
      return this.getOriginalOrderItemStatus(item) || LocalOrderItemStatusEnum.UserReview
    },
    getOriginalOrderItemStatus(item: LocalOrderItem): LocalOrderItemStatusEnum | undefined {
      const originalItem: LocalOrderItem | undefined = this.getOriginalOrderItem(item)
      return originalItem
        ? new LocalOrderItem(originalItem).getOnlyCountStatus()
        : item.getOnlyCountStatus()
    },
    getOriginalOrderItem(item: LocalOrderItem): LocalOrderItem | undefined {
      return this.getOriginalOrder()?.order_items?.find((originalItm) => originalItm.id === item.id)
    },
    getOriginalOrder(): LocalOrder {
      return (this.orderProp as OrderDialogObject).order
    },
    cancelExistingOrderItem(item: LocalOrderItem) {
      if (item.isAbleToBeCanceled) {
        this.cancelOrderItem(item)
      } else if (!this.dialogOrder.getItem(item.id)?.isFinalized) {
        this.unCancelOrderItemInUserReview(item)
      }
    },
    cancelOrderItem(item: LocalOrderItem) {
      if (item.isNew) {
        this.removeNewOrderItem(item)
      } else if (this.userReview) {
        this.cancelOrderItemInUserReviewLocally(item)
      } else {
        this.cancelExistingItemNotInUserReview(item)
      }
    },
    cancelExistingItemNotInUserReview(item: LocalOrderItem) {
      try {
        if (!item.isPreProvisioning && item.hasRemainingUncanceledStatusesOrCountsOfStatuses) {
          this.cancelDialogItemCount(item)
        } else {
          this.cancelDialogItemWithoutCount(item)
        }
      } catch (error) {
        throwError('Error While Canceling Order Item', error)
      }
    },
    async cancelDialogItemWithoutCount(item: LocalOrderItem) {
      if (await this.confirmCancelOrderItem(item)) {
        const cancelItemFunction = async () => await this.cancelExistingItemWithoutCount(item)
        await runFunctionWithLoadingSpinner(cancelItemFunction.bind(this))
      }
    },
    async cancelDialogItemCount(item: LocalOrderItem) {
      const result: CancelItemCountReturnType = await confirmCancelStatusCountDialog(
        item,
        this.getItemCancellationMessage(item),
        this.getItemCancelationAlert()
      )
      const cancelItemFunction = async () =>
        await item.cancelWithCount(result.statusCount, result.cancelEntireItem)
      if (result.confirmed) await runFunctionWithLoadingSpinner(cancelItemFunction.bind(this))
    },
    getItemCancelationAlert(): AlertMessage | undefined {
      if (this.dialogOrder?.cancelableItems.length === 1) {
        return {
          message: 'Please Note: This is the final active Item.\nProceeding will cancel the Order.',
          messageType: MessageTypes.WARNING
        }
      }
      return undefined
    },
    getItemCancellationMessage(item: LocalOrderItem): string {
      return `Are you sure you want to cancel item ${item.id} for order ${this.dialogOrder.id}?`
    },
    cancelOrderItemInUserReviewLocally(item: LocalOrderItem) {
      const dialogOrderItem: LocalOrderItem | undefined = this.dialogOrder.getItem(item.id)
      if (dialogOrderItem) {
        dialogOrderItem.setOnlyCountStatus(LocalOrderItemStatusEnum.Cancelled)
        item.setOnlyCountStatus(LocalOrderItemStatusEnum.Cancelled)
      }
    },
    async confirmCancelOrderItem(item: LocalOrderItem): Promise<boolean> {
      const dialogLabel: string = 'Cancel Order Item?'
      return await confirmDialog(
        dialogLabel,
        this.getItemCancellationMessage(item),
        this.getItemCancelationAlert()
      )
    },
    async cancelExistingItemWithoutCount(item: LocalOrderItem): Promise<any> {
      // Variable Item SKU must have custom text - even if it's being canceled.
      const skuType = this.dialogSkusList.find((sku: LocalSku) => sku.id === item.sku)?.design_type
      if (
        skuType === SkuDesignTypeEnum.Variable &&
        !this.dialogOrder.getItem(item.id)?.custom_text
      ) {
        item.custom_text = 'undefined'
        await item.update()
      }

      if (this.dialogOrder.unFinalizedItems.length > 1) {
        // Multiple Items Still Active, Cancel This One
        await item.cancel()
        const orderOrderItem = this.dialogOrder.order_items?.find(
          (orderItem) => orderItem.id === item.id
        )
        if (orderOrderItem) {
          orderOrderItem.setOnlyCountStatus(LocalOrderItemStatusEnum.Cancelled)
        }
        item.setOnlyCountStatus(LocalOrderItemStatusEnum.Cancelled)
        orderUpdated(this.dialogOrder)
      } else {
        // Last Item Uncanceled Remaining, Cancel Order
        await this.dialogOrder.cancel()
        await api.getOrder(this.dialogOrder.id || -1).then((order) => {
          item.setOnlyCountStatus(LocalOrderItemStatusEnum.Cancelled)
          this.dialogOrder = order
          orderUpdated(this.dialogOrder)
        })
      }
    },
    async saveNewOrder() {
      await this.dialogOrder.create(this.dialogOrderItems)
    },
    async saveOrder() {
      if (!this.dialogOrderItems || this.dialogOrderItems.length < 1) {
        showSpinner(false)
        newErrorDialog('Invalid Order', 'Order must have at least one item')
        return
      }
      showSpinner(true, (this.dialogOrder.order_items?.length || 0) + 1)
      try {
        if (this.dialogOrder.id) {
          await this.dialogOrder.update()
        } else {
          await this.saveNewOrder()
        }
        this.closeDialog()
      } catch (error) {
        throwError('Error While Saving Order', error)
      }
      showSpinner(false)
    },
    async reviewOrder() {
      if (await confirmDialog(this.getReviewConfirmTitle(), this.getReviewConfirmMessage())) {
        showSpinner(true, this.dialogOrderItems.length + 2)
        setGlobalUpdatingFlag(true)
        try {
          let newlyAddedItemsIDs: Array<number> = await this.addNewItemsAndReturnIDs()
          if (this.orderHasNoRemainingActiveItems()) {
            this.dialogOrder = await this.dialogOrder.cancel()
            setGlobalUpdatingFlag(false)
            orderUpdated(this.dialogOrder)
          } else {
            await this.cancelExistingItemsRequiringCancelation()
            await this.updateRemainingExistingOrderItems(newlyAddedItemsIDs)
            await this.updateAndRefreshDialogOrder()
            setGlobalUpdatingFlag(false)
            await this.dialogOrder.userReviewed(this.preStockedItems)
          }
          this.closeDialog()
        } catch (error) {
          throwError('Error While Submitting Order Review', error)
        }
        setGlobalUpdatingFlag(false)
        showSpinner(false)
      }
    },
    getReviewConfirmTitle(): string {
      return (
        'Confirm Review for Order ' +
        this.dialogOrder.id +
        ' and ' +
        this.dialogOrderItems.length +
        ' Item' +
        (this.dialogOrderItems.length > 1 ? 's' : '') +
        '?'
      )
    },
    getReviewConfirmMessage(): string {
      const newOrderItems = this.dialogOrderItems.filter((item) => !item.order)?.length || 0
      const nonCncldItms = this.dialogOrderItems.filter((i) => !i.isFinalized)?.length || 0
      return nonCncldItms < 1
        ? 'All items have been Finalised or Canceled, Cancel Order?'
        : 'Save' +
            (newOrderItems > 0
              ? ' ' + newOrderItems + (newOrderItems > 1 ? ' new items ' : ' new item ')
              : ' ') +
            'and mark Order and All Order Items as Reviewed?'
    },
    async addNewItemsAndReturnIDs(): Promise<Array<number>> {
      let newIDs: Array<number> = []
      for (const item of this.dialogOrderItems) {
        if (item.isNew && !this.orderContainsItemID(item.id)) {
          const index: number = this.dialogOrderItems.indexOf(item)
          const savedItem: LocalOrderItem = await this.dialogOrder.addItem(item, false)
          this.dialogOrderItems[index] = savedItem
          this.dialogOrderItems[index].setOnlyCountStatus(LocalOrderItemStatusEnum.UserReview)
          this.updatePrestockedItemIfExists(item, savedItem)
          this.dialogOrder.order_items?.push(savedItem)
          newIDs.push(savedItem.id as number)
        }
      }
      return newIDs
    },
    updatePrestockedItemIfExists(oldItem: LocalOrderItem, newItem: LocalOrderItem): void {
      const index: number = this.preStockedItems.findIndex((i) => i.item.id === oldItem.id)
      if (index > -1) this.preStockedItems[index].item = newItem
    },
    orderContainsItemID(id?: number | null): boolean {
      if (!id || !this.dialogOrder?.order_items) return false
      return this.dialogOrder.order_items.some((item) => item.id === id)
    },
    async cancelExistingItemsRequiringCancelation(): Promise<void> {
      for (const item of this.dialogOrderItems) {
        if (this.existingOrderItemIsNewlyCanceled(item))
          await this.cancelExistingItemWithoutCount(item)
      }
    },
    existingOrderItemIsNewlyCanceled(item: LocalOrderItem): boolean {
      const original: LocalOrderItem | undefined = this.dialogOrder.getItem(item.id)
      return !item.isNew && item.hasStatusCancelled && !!original && !original.hasStatusCancelled
    },
    orderHasNoRemainingActiveItems(): boolean {
      return this.dialogOrderItems.every((item) => item.isFinalized)
    },
    async updateAndRefreshDialogOrder(): Promise<void> {
      this.dialogOrder = await this.dialogOrder.update(false)
    },
    async updateRemainingExistingOrderItems(newItemsIDs: number[]): Promise<void> {
      for (const item of this.dialogOrderItems) {
        if (this.itemIsNotNew(newItemsIDs, item)) await item.update(false)
      }
    },
    itemIsNotNew(newlyAddedItemsIdList: number[], item: LocalOrderItem): boolean {
      const itemIsNew = item.id && newlyAddedItemsIdList.includes(item.id)
      return !item.isNew && !itemIsNew
    },
    async submitOrder() {
      if (this.userReview) {
        await this.reviewOrder()
      } else {
        await this.saveOrder()
      }
    },
    closeDialog() {
      // Transmits dialogOpen value to <view-order-dialog-content v-model:dialog-open="dialogOpen" />
      this.$emit('update:dialogOpen', false)
    },
    isEditable(fieldName: string): boolean {
      return (
        this.editable ||
        this.editableFields?.find(
          (field) =>
            field.replace(/[_-]/g, '').toLowerCase() ===
            fieldName.replace(/[_-]/g, '').toLowerCase()
        ) !== undefined ||
        false
      )
    },
    validateOrder(order: LocalOrder): LocalOrder {
      if (!this.editable && (!order.status || !order.id)) {
        throwError(
          'Error, Invalid Dialog Order.',
          'An Invalid Order was provided to the Order Dialog.'
        )
      }
      return new LocalOrder(order)
    },
    instantiateLocalVariables() {
      try {
        this.setOrderAndItems(this.validateOrder((this.orderProp as OrderDialogObject).order))
        this.userReview = (this.orderProp as OrderDialogObject).userReview || false
      } catch (e) {
        throwError('Error in OrderDialog props.', e)
        this.closeDialog()
      }
    },
    createItemsUpdateListener() {
      const updateItem = () => {
        if (this.dialogOrder?.id) {
          api.getOrder(this.dialogOrder.id).then((o: LocalOrder) => this.setOrderAndItems(o))
        }
      }
      createUpdatedOrderListener(updateItem.bind(this))
      createUpdateditemListener(updateItem.bind(this))
      createUpdateditemsListener(updateItem.bind(this))
    },
    setOrderAndItems(newOrder: LocalOrder) {
      this.dialogOrder = newOrder
      this.dialogOrderItems =
        newOrder.order_items?.map((item: OrderOrderItemsInner) => new LocalOrderItem(item)) || []
    },
    async updateSkuDataIfNecessary() {
      this.loading = true
      if (this.requireNewSkus(this.dialogOrderItems)) await this.retrieveRelevantSkus()
      this.assignPostProcessingRequiredToItems()
      this.loading = false
    },
    async retrieveRelevantSkus() {
      this.dialogSkusList = await api.getSkusIn(this.getUniqueRequiredSkuIDs())
      this.initialSku = await getInitialSku()
    },
    getUniqueRequiredSkuIDs(): number[] {
      const itemSkuIDs: number[] = this.dialogOrderItems.map((item) => item.sku || -1)
      return itemSkuIDs.filter((sku, index, self) => self.indexOf(sku) === index && sku > -1)
    },
    assignPostProcessingRequiredToItems(): void {
      this.dialogOrderItems.forEach((item: LocalOrderItem) => {
        if (item.requires_post_processing == undefined)
          item.requires_post_processing = !!this.dialogSkusList.find((s) => s.id === item.sku)
            ?.requires_post_processing
      })
    },
    getOrderItemLabel(i: LocalOrderItem): string {
      const label = this.dialogSkusList.find((s: LocalSku) => s.id === i.sku)?.label
      return 'Order Item ' + (i.order ? i.id + ' ' : '') + (label ? ': ' + label : '')
    },
    setItemPreStocked(ips: ItemPrestocked) {
      if (ips.prestocked) {
        const count: number = ips.count ? ips.count : ips.item.hasCount ? ips.item.countAsNumber : 1
        const index: number = this.preStockedItems.findIndex((i) => i.item.id === ips.item.id)
        if (index > -1) this.preStockedItems[index].count = count
        else this.preStockedItems.push({ item: ips.item, count: count })
      } else {
        this.removeItemFromPrestockedItems(ips.item)
      }
    },
    removeItemFromPrestockedItems(item: LocalOrderItem) {
      this.preStockedItems = this.preStockedItems.filter(
        (prestockedItem) => prestockedItem.item.id !== item.id
      )
    },
    requireNewSkus(newItems: LocalOrderItem[]): boolean {
      if (newItems.length > 0 && this.dialogSkusList.length > 0) {
        return newItems.some((i) => this.dialogSkusList.findIndex((s) => s.id === i.sku) === -1)
      }
      return !this.initialSku || this.dialogSkusList.length === 0
    }
  },
  created() {
    this.instantiateLocalVariables()
    this.createItemsUpdateListener()
  },
  watch: {
    dialogOrderItems: {
      handler() {
        this.updateSkuDataIfNecessary()
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    ButtonInput,
    DialogContent,
    TextInput,
    TextFieldInput,
    ExpandableContent,
    DocumentPlusIcon,
    TrashIcon,
    OrderItemInnerContentComponent,
    DateInput,
    StatusReadOnlyInput
  }
})
</script>
